<template>
  <div>
    <!-- 所属组织选择框 -->
    <div
      class="dept-select"
      :style="{
        padding: deptList.length ? '5px 12px 0px 12px' : '0 0 0 12px',
        'line-height': deptList.length ? 'auto' : '32px',
        maxHeight: '100px',
        overflow: 'auto'
      }"
      @click="onClick"
    >
      <template v-if="deptList.length">
        <Draggable :list="deptList" item-key="departmentId" :animation="300" :sort="true" class="printer">
          <template #item="{ element, index }">
            <div class="pt-tag">
              <span class="pt-name" :title="element.departmentName">{{ element.departmentName }}</span>
              <div @click.stop="onDel(index)" class="pt-close">
                <CloseOutlined />
              </div>
            </div>
          </template>
        </Draggable>
      </template>
      <span v-else>请选择所属组织</span>
    </div>
    <!-- 所属组织弹窗 -->
    <a-modal
      centered
      title="所属组织"
      width="655px"
      class="global-range-modal"
      :visible="isShowRangeModal"
      @cancel="globalSelectorCancel"
      @ok="globalSelectorOk"
    >
      <GlobalSelector
        :selectorInfo="selectorInfo"
        :visible="isShowRangeModal"
        :selectedList="selectedList"
        :renderList="renderList"
        @getSearchInfo="getSearchInfo"
        @toNext="toNext"
        ref="globalSelector"
      ></GlobalSelector>
    </a-modal>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, reactive } from 'vue'
import Draggable from 'vuedraggable'
import { CloseOutlined } from '@ant-design/icons-vue'
import GlobalSelector from '@/components/GlobalSelector/index.vue'
import { getDepartmentOrStaff } from '@/apis/businessManage'
import { formateListData } from '@/utils/selectorListFormate.js'

const props = defineProps({
  deptList: {
    type: Array,
    default: () => []
  },
  //是否不需要权限控制
  noPermissionControl: {
    type: Boolean,
    default: true
  }
})
const emits = defineEmits(['update:deptList', 'change'])
// deptList 格式
// [
//   {
//     departmentId: item.departmentId,
//     departmentName: item.departmentName
//   },
//   ...
// ]

const isShowRangeModal = ref(false) //所属组织弹窗显示隐藏
const globalSelector = ref(null)
const renderList = ref([])
const selectedList = ref([])
const selectorInfo = reactive({
  placeholder: '请输入组织名称',
  isSelectDept: true
})

// 点击选择框，选择所属组织，会自动更新 deptIdList
function onClick() {
  isShowRangeModal.value = true

  console.log('props.deptList', props.deptList)

  let arr = props.deptList
  selectedList.value = arr.map(item => {
    item.dataName = item.departmentName
    item.dataType = 2
    item.dataId = item.departmentId
    item.icon = require('@/assets/images/checkStaff/level.png')
    return item
  })
}

// 删除
function onDel(index) {
  props.deptList.splice(index, 1)
  emits('change')
}

//弹窗确定事件
const globalSelectorOk = () => {
  let arr = [...globalSelector.value.okEvent()]
  let selectedDepartment = []
  arr.forEach(item => {
    selectedDepartment.push({
      departmentId: item.dataId,
      departmentName: item.dataName
    })
  })
  emits('update:deptList', selectedDepartment)
  globalSelectorCancel()
}
//弹窗取消事件
const globalSelectorCancel = () => {
  isShowRangeModal.value = false
  globalSelector.value.cancelEvent()
}
//搜索
const getSearchInfo = async e => {
  let data = {
    searchName: e
  }
  getScopeList(data)
}
//弹窗数据
const getScopeList = async data => {
  let params = {
    selectorType: 2, //1部门人员2部门3员工
    allData: props.noPermissionControl
  }
  const res = await getDepartmentOrStaff({ ...params, ...data })
  if (res.success) {
    console.log('全部数据', res)
    handelData(res)
  }
}
//处理左侧数据
const handelData = res => {
  renderList.value = formateListData(res)
  console.log('处理后的renderList的值', renderList.value)
}
//下一级
const toNext = id => {
  let data = {
    departmentId: id
  }
  getScopeList(data)
}
onMounted(() => {
  console.log('接收到的所属组织的值', props.deptList)
  getScopeList()
})
</script>

<style lang="less" scoped>
.dept-select {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  padding: 6px 24px 1px 10px;
  color: #bfbfbf;
  line-height: 32px;
  cursor: pointer;

  &:hover {
    border-color: #d9d9d9;    //请选择所属组织边框颜色
    border-left: #d9d9d9 !important;
  }
}
.printer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: 8px;
  transition: border-color 0.3s;

  &::-webkit-scrollbar {
    display: block;
  }

  .pt-tag {
    white-space: nowrap;
    background: #fbfbfc;
    border: 1px solid #dcdee0;
    padding: 0 4px 0 7px;
    font-size: 12px;
    height: 20px;
    // line-height: 20px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 5px;
    user-select: none;
    cursor: move;
  }

  .pt-name {
    color: rgba(0, 0, 0, 0.65);
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 7px;
    white-space: nowrap;
    display: inline-block;
    flex: 1;
    max-width: 10em;
  }

  .pt-close {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #333;
    }
  }

  .down-arrow {
    position: absolute;
    font-size: 12px;
    top: 10px;
    right: 11px;
    color: rgba(0, 0, 0, 0.3);
  }
}
</style>
